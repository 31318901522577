@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro);
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: roboto;
} */



body {
  margin: 0;
  font-family: 'Work Sans';
  color: #254cdd;
  /* color: rgb(212, 213, 213); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#promotion-content {
  color: rgb(0, 0, 0);
}

h1 {
  font-family: Cochin;
  font-style: bold;
  color: rgb(0, 0, 0);
}

#promotion {
  color: rgb(0, 0, 0);
}

h2 {
  font-family: Cochin;
  font-style: italic;
  color: rgb(0, 0, 0);
}

p {
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
}

ul {
  color: rgb(0, 0, 0);
  font-size:  18px;
  letter-spacing: 1px;
}

code {
  font-family: source-code-pro, Crenzo, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link {
  color: rgb(139, 139, 253);
}

/* visited link */
a:visited {
  color: rgb(0, 195, 255);
}

/* mouse over link */
a:hover {
  color: hotpink;
  cursor:pointer;
}

/* selected link */
a:active {
  color: blue;
}

/* Social Follow CSS */
.social-container {
  padding: 25px 50px;

}

a.social {
  margin: 0 1rem;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  display: inline-block;
}

a.social:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  cursor:pointer;
}

/* Minter CSS */
.Minter {
  max-width: 100vh;
  /* max-height: 80vh; */
  padding: 30px 90px;
  margin: 0 auto;
  text-align: left;
}

/* input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
} */

button {
  padding: 15px 20px;
  /* max-height: 50px; */
  border-color: hsl(240, 94%, 65%);
  color: rgb(139, 139, 253);
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  font-family: 'Anonymous Pro', sans-serif;
  cursor:pointer;
}

button:hover {
  color: rgb(255, 0, 162);
  border-color: rgb(0, 195, 255);
  cursor:pointer;
}

#title {
  padding-top: 32px;
}

#status {
  /* color:rgb(141, 141, 255); */
  color:rgb(255, 0, 162);
  font-weight: bold;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: right;
  float:right;
  margin-bottom: 50px;
  line-height: 16px;
  font-family: 'Anonymous Pro', sans-serif;
  
}



#bundleMintButton {
  margin-top: 0px;
  background-color: #254cdd;
  color:white;
}
#bundleMintButton:hover {
  background-color: #3b60e6;
  color:rgb(255, 255, 255);
  border-color: rgb(0, 195, 255);
  cursor:pointer;
}

#bundleMintButton:disabled,
button[disabled] {
  margin-top: 0px;
  background-color: #cccccc;
  border-color: #d8d8d8;
  color:rgb(122, 117, 117);
  pointer-events: none;
}

#mintButton {
  margin-top: 20px;
  background-color: hsl(227, 73%, 51%);
  color:white;
}
#mintButton:hover {
  background-color: #3b60e6;
  color:rgb(255, 255, 255);
  border-color: rgb(0, 195, 255);
  cursor:pointer;
}

#mintButton:disabled,
button[disabled] {
  margin-top: 20px;
  background-color: #cccccc;
  border-color: #d8d8d8;
  color:rgb(122, 117, 117);
  pointer-events: none;
}
.App {
  text-align: center;
  /* background-image: url(./images/landing.png);
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center; */
  background-color: rgb(255, 255, 255);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

